import { Injectable } from '@angular/core';
import { AwareModel, Role, RolePivot } from '@appbolaget/aware-model';
import { AllPermissions, CrudPermissionKeys, ECrudPermission, PermissionFormInput, PermissionFormOutput } from './symbols';
import { AwareHttpRequest, AwareHttpService } from '@appbolaget/aware-http';

@Injectable({ providedIn: 'root' })
export class CrudPermissionsService {
    constructor(private api: AwareHttpService) {}

    doesPermissionFormOutputHaveChangedValues(form: PermissionFormOutput): boolean {
        if (!form) {
            return false;
        }

        // If not array, it has not changed
        if (!Array.isArray(form)) {
            return false;
        }

        // In this case, its an array of roles, which means it has not been touched
        if (!form[0]?.role) {
            return false;
        }

        return true;
    }

    getAttachAndDetachRequests<T extends AwareModel = Role>(
        form: PermissionFormOutput,
        existingPermissionModels: T[],
        uuid: string,
        type: string,
    ): [AwareHttpRequest, AwareHttpRequest] {
        const anyRoleHasNotAllPermissions =
            form.some((role) => {
                const permissionValue = this.getPermissionValue(role.permissions);
                return permissionValue !== AllPermissions;
            }) || form.length !== existingPermissionModels.length;

        const attachBody =
            anyRoleHasNotAllPermissions &&
            form.map((role) => {
                const permissionValue = this.getPermissionValue(role.permissions);
                return {
                    id: role.role.uuid,
                    fields: {
                        permissions: permissionValue,
                    },
                };
            });

        const detachBody =
            !anyRoleHasNotAllPermissions &&
            existingPermissionModels.map((role) => {
                return {
                    id: role.uuid,
                };
            });

        const attach = attachBody?.length ? this.api.put(`${type}/${uuid}/attach/roles`, attachBody).stream('up') : null;
        const detach = detachBody?.length ? this.api.put(`${type}/${uuid}/detach/roles`, detachBody).stream('up') : null;

        return [attach, detach];
    }

    getPermissionValue(permissions: Array<{ permission: CrudPermissionKeys; value: boolean }>): number {
        return permissions
            .filter((permission) => !!permission.value)
            .reduce((acc, permission) => (acc += ECrudPermission[permission.permission]), 0);
    }

    getCleanPermissionValue(...permissions: ECrudPermission[]): number {
        return permissions.reduce((acc, permission) => (acc += permission), 0);
    }

    getPermissionFormInput<TPivot extends RolePivot>(roles: Role<TPivot>[]): PermissionFormInput {
        return roles.reduce((acc, role) => {
            acc[role.uuid] = parseInt(role.pivot.permissions);
            return acc;
        }, {});
    }

    getPermissionFormInputFromOutput(form: PermissionFormOutput): PermissionFormInput {
        return form.reduce((acc, role) => {
            acc[role.role.uuid] = this.getPermissionValue(role.permissions);
            return acc;
        }, {});
    }

    getPermissionKeysFromPermissionValue(value: number): CrudPermissionKeys[] {
        return Object.keys(ECrudPermission)
            .map((key) => Number(ECrudPermission[key]))
            .filter((permission) => value & permission)
            .map((permission) => ECrudPermission[permission] as CrudPermissionKeys);
    }
}
